.community-body {
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr;
    align-items: center;
    height: 100%;
    padding: 20px; 
    color: white;
    gap: 20px; 
    margin: 0 auto;
}

.community-meet-header{
    margin-top: 30px;
    font-family: 'Josefin Sans';
    font-size: 3rem;
    text-align: center;
}

.community-discord-grid{
    margin-top: 30px;
    display:grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
    gap: 75px;
    
}


.community-discord-card{
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    height: auto; /* Maintain aspect ratio */
    padding: 20px;
    position: relative;
    box-shadow: 0px 0px 10px 1px rgb(255, 255, 255);
    background-image: linear-gradient(180deg, black 75%, transparent);
    font-family: 'Ubuntu';
    width: auto;
    height: 175px;
}

.community-discord-header-flexbox{
    display: flex;
    justify-content: space-between;
}
.community-discord-header{
    font-weight:600;
    font-size: 1.5rem;
}

.community-discord-card-button{
    width: 50px;
    font-family: 'Ubuntu';
    font-size: 1rem;
    background-color: black;
    color: white;
    box-shadow: 0px 0px 5px 1px white;
    border-radius: 15%;
    -webkit-border-radius: 15%;
    -moz-border-radius: 15%;
    -ms-border-radius: 15%;
    -o-border-radius: 15%;
}

.community-discord-card-text{
    position: relative;
}

.community-ranked-container{
    display: grid;
    justify-content: center;
    align-items: center;
}
.community-ranked-banner{
    width: 100%;
    box-shadow: 0px 0px 10px 1px white;
}



@media (max-width:1450px){
    .community-discord-grid{
        grid-template-columns: 1fr 1fr;
        margin: 0 auto;
    }

    .community-discord-card{
        width: auto;
    }
}

@media (max-width:950px){
    .community-discord-grid{
        grid-template-columns: 1fr; 
        margin: 0 auto;

    }

    .community-discord-card{
        width: auto;
        height: auto;
    }
}





