.App {
  text-align: center;
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

}

.App-link {
  color: #61dafb;
}

.disclaimer-box{
  margin-bottom: 10px;
  justify-content: center;
  width: auto;
  bottom: 0;
  text-align: center;

}
.disclaimer-text{
  font-size: 1.1rem;
  margin: 0 auto;
  text-align: center;
  color: white;
  font-family: 'Josefin Sans';
}

@media (max-width: 520px){

  .disclaimer-text{
    font-size: 0.9rem;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
