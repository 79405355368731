.deck-body {
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr;
    align-items: center;
    height: 100%;
    padding: 20px; 
    gap: 30px;
}

.deck-header {
    color: white;
    font-size: 3rem;
    align-items: center;
    justify-content: center;
    text-align: center;   
    font-family: 'Josefin Sans'; 
    font-weight: 700;
}

.deck-header-author {
    color: white;
    font-size: 2rem;
    align-items: center;
    justify-content: center;
    text-align: center;    
    font-family: 'Josefin Sans';
}
.deck-decklist {
    align-items: center;
    justify-content: center;
    max-width: 1100px;
    margin: 0 auto;
}

.deck-spellBox {
    margin-top: 5px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    justify-content: center;
    box-shadow: 0px 0px 10px 1px white;
    max-width: 1100px;
    padding: 20px;
    gap: 30px;
}

.deck-spell-img {
    width: 100%; 
    height: auto; 
}

.deck-subheader{
    color: white;
    font-size: 3rem;
    align-items: center;
    justify-content: center;
    font-family: 'Josefin Sans';

}

.deck-subheader-box{
    position: relative;
}

.deck-subheader-button {
    color: white;
    background-color: rgba(0,0,0,0.6);
    font-size: 3rem;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}


.deck-gameplan-container {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    max-width: 1100px;
}

.deck-gameplan-box{
    box-shadow: 0px 0px 10px 1px white;
    background-color: rgba(0,0,0,0.6);
    min-height: 50px;
    padding: 10px;
}

.deck-gameplan-header{
    color: white;
    font-size: 3rem;
    align-items: center;
    justify-content: center;
    font-family: 'Josefin Sans';

}

.deck-gameplan-text{
    color:white;
    font-size: 1.5rem;
    font-family: 'Ubuntu';
    line-height: 35px;
    white-space: pre-wrap;
}

.deck-combo-container {
    max-width: 1100px;
    margin: 0 auto;
    font-family: 'Josefin Sans';
}


.deck-thead th:nth-child(1){
    width: 10%;
}

.deck-thead th:nth-child(2){
    width: 45%;
}
.deck-thead th:nth-child(3){
    width: 45%;
}

.deck-spell-img {
    width: 100%; 
    height: auto; 
}

.deck-table-spell-container{
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(5, 1fr);
    justify-content: center;

}

.deck-table-spell-image{
    position: relative;
    width: 100%;
    height: auto;
}

@media (min-width: 1100px) {
    .deck-gameplan-container{
        margin: 0 auto;
        min-width: 1100px;
    }
}

@media (max-width: 1100px) {
    .deck-subheader{
        text-align: center;
    }
    .deck-gameplan-header{
        text-align: center;
    }

}

@media(max-width: 520px){
    .deck-spellBox{
        gap: 1px;
        padding: 5px;
    }
    .deck-table-spell-container{
        gap:1px;
        
    }
    .deck-gameplan-text{
        font-size: 1.1rem;
        line-height: normal;
    }
   
}
