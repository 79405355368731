.body {
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr;
    align-items: center;
    height: 100%;
    padding: 20px; 
    margin: 0 auto;
    
}


.header {
    color: white;
    font-size: 5rem;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: 'Josefin Sans';
    font-weight: 600;

    
}

.charBox {
    display: flex;
    color: white;
    text-align: center;
    font-size: 2.5rem;
    box-shadow: 0px 0px 10px 1px white;
    align-items: center;
    margin: 0 auto;
    max-width: 1100px;
    gap: calc(1100px * .01);
    font-family: 'Ubuntu';
}


.charImg {
    text-align: center;
    width: 100%;
    
}

.charText {
    position: relative;
}

.liked-spells {
    align-items: center;
    justify-content: center;
    max-width: 1100px;
    margin: 0 auto;

}

.liked-text {
    color: white;
    font-size: 3rem;
    align-items: center;
    font-family: 'Josefin Sans';
    margin-top: 30px;
    
}

.spellBox {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    justify-content: center;
    box-shadow: 0px 0px 10px 1px white;
    max-width: 1100px;
    padding: 20px;
    gap: 30px;

}

.spell-img {
    width: 100%; 
    height: auto; 
}

.deck-container{
    align-items: center;
    max-width: 1100px;
    margin: 0 auto;
    font-family: 'Josefin Sans';

}



table{
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0px 0px 10px 1px white;
    color: white;
    font-size: 1.5rem;
}

thead th:nth-child(1){
    width: 20%;
}

thead th:nth-child(2){
    width: 20%;
}
thead th:nth-child(3){
    width: 60%;
}

th {
    background-color:  rgba(0, 0, 0, 0.5);
    border: 1px solid white;
    padding-top: 5px;
    word-wrap: break-word;

}
td {
    padding: 20px;
    border: 1px solid white;
    word-wrap: break-word;
    white-space: normal;
}

.deck-name-link {
    text-decoration: underline;
    color: white;
}

.character-deck-name-box{
    display: flex;
    flex-direction: column;
}

.character-deck-name-button{
    background-color: black;
    color: white;
}

.character-deck-name-button:hover{
    background-color: rgb(54, 54, 54)
}

tbody td{
    text-align: center;
}

tbody tr:nth-child(odd){
    background-color: rgba(55, 55, 55, 0.5);
    
}
tbody tr:nth-child(even){
    background-color:  rgba(0, 0, 0, 0.5);
}


.table-spell-container{
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(5, 1fr);
    justify-content: center;

}

.table-spell-image{
    position: relative;
    width: 100%;
    height: auto;
}

@media (max-width: 1000px) {
    .charBox {
      flex-direction: column;
      max-width: 700px;
    }
    .charImg{
        max-width: fit-content;
        max-width: 700px;
    }
    .charText{
        max-width: 700px;
    }
    .liked-spells{
        text-align: center;
    }

    .deck-container{
        text-align: center;
    }

    table{
        font-size: 1.3rem;
    }
}


@media(max-width: 520px){
    .header{
        font-size: 3.5rem;
    }
    .charBox{
        font-size: 1.5rem;
    }
    .table-spell-container{
        gap: 1px;
    }
    .spellBox{
        gap:1px;
        padding: 5px;
    }

    table{
        font-size: 1rem;
    }

    td{
        padding: 5px;
    }
}

@media(max-width: 400px){
    .charBox{
        font-size: 1.5rem;
    }
}