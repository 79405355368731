.cardContainer {
    padding-left: 5%;
    padding-right: 7%;
    overflow-x: hidden; /* Prevent horizontal scroll */
}

.characterCards {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Default: 4 columns */
    gap: 0px; /* Default gap between cards */
    justify-content: center;
}

.grid-card {
    width: 100%; /* Card width fills the grid cell */
    height: auto; /* Maintain aspect ratio */
    padding: 20px; /* Default padding around each card */
}

.grid-card img {
    width: 100%;
    height: auto;
    text-align: center;
    position: relative;
    transition: transform 0.3s ease,filter 0.3s ease;
    filter: saturate(0.0);
    -webkit-transition: transform 0.3s ease,filter 0.3s ease;
    -moz-transition: transform 0.3s ease,filter 0.3s ease;
    -ms-transition: transform 0.3s ease,filter 0.3s ease;
    -o-transition: transform 0.3s ease,filter 0.3s ease;
    -webkit-filter: saturate(0.0);
}

.grid-card img:hover {
    transform: scale(1.1);
    filter: saturate(1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
}




@media (max-width: 1200px) {
    .grid-card {
        padding: 15px; 
    }
    .characterCards {
        gap: 15px; 
    }
}

@media (max-width: 900px) {
    .grid-card {
        padding: 10px; 
    }
    .characterCards {
        gap: 10px; 
    }
}

@media (max-width: 600px) {
    .grid-card {
        padding: 5px; 
    }
    .characterCards {
        gap: 5px; 
    }
}
