.forms-body{
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr;
    align-items: center;
    height: 100%;
    padding: 20px;
    color: white;
    gap: 20px;
    margin: 0 auto;

}

.forms-form-navbar-container{
    display: flex;
    justify-content: space-around;
    font-family: 'Josefin Sans';
    font-size: 1.5rem;
}

.forms-form-navbar-left{
    background-color: transparent;
    box-shadow: 0px 0px 5px 1px white;
    width: 100%;
    text-align: center;
    height: auto;
    padding: 15px;
}
.forms-form-navbar-right{
    background-color: transparent;
    box-shadow: 0px 0px 5px 1px white;
    width: 100%;
    text-align: center;
    height: auto;
    padding: 15px;
}

.forms-form-navbar-left:hover{
    background-color: rgba(255,255,255,0.3);
}
.forms-form-navbar-right:hover{
    background-color: rgba(255,255,255,0.3);
}
.forms-compform-container{
    box-shadow: 0px 0px 10px 1px white;
    justify-content: center;
}

.forms-googleform{
    background-color: rgba(255,255,255,0.7);
    overflow: hidden;
    width: 100%;

}