
.sidebar-menu-container{
    background-image: linear-gradient(90deg, black 95%, transparent);
    width: 70%;
    height: 100%;
    z-index: 2000;
    position: fixed;
    
}


.sidebar-hidden{
    visibility: hidden;
}

.sidebar-x-button{
    position: absolute;
    top: 10px;
    right: 5%;
    width: 30px;
    height: 30px;

}
.sidebar-menu-buttons{
    display:flex;
    flex-direction: column;
    gap: 30px;
    padding: 30px;
}

.sidebar-button{
    text-decoration: none;
    font-family: 'Josefin Sans';
    font-size: 1.5rem;
    color: white;
}

.sidebar-button:hover{
    text-decoration: underline;
}





