body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, 'Josefin Sans';

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  /*Background color is for darkening the background image */
  background-color: rgba(0,0,0,0.7);
  background-blend-mode: multiply;
  background-image: url(DuelistsBanner.png);
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size:cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
