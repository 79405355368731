.about-body {
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr;
    align-items: center;
    height: 100%;
    padding: 20px;
    color: white;
    gap: 20px;
}

.about-header{
    margin-top: 20px;
    text-align: center;
    font-family: 'Josefin Sans';
    font-size: 3rem;
    margin-bottom: 20px;
}

.about-box-whatis{
    padding: 10px;
    max-width: 1100px;
    margin: 0 auto;
    box-shadow: 0px 0px 10px 1px white;
    font-family: 'Ubuntu';
    font-size: 1.5rem;
    line-height: 35px;
    white-space: pre-wrap;
}


