.navbar-body{
    z-index: 1000;
    background-image: linear-gradient(180deg, black 75%, transparent);
    top: 0;
    position: sticky;


}

.navbar {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-image: linear-gradient(180deg, black 75%, transparent);
    padding:1rem;
    z-index: 1001;
    max-height: 100px;
    overflow-x: hidden;
    overflow-y: hidden;
}
.navbar-right{
    display: flex;
    gap: 20px;
}

.navbar-center{
    flex:1;
    display: flex;
    justify-content: start;
}

.navbar-button{
    text-decoration: none;
    font-family: 'Josefin Sans';
    font-size: 1.5rem;
    color: white;
}

.navbar-button:hover{
    text-decoration: underline;
}

.site-logo{
    width: 125px;
    height: 125px;
}



.navbar-menu-icon-shadow{
    box-shadow: inset 0px 0px 0px 100px rgba(167, 167, 167,0.0);
    transition: box-shadow 0.3s ease;
    padding: 10px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    -webkit-transition: box-shadow 0.3s ease;
    -moz-transition: box-shadow 0.3s ease;
    -ms-transition: box-shadow 0.3s ease;
    -o-transition: box-shadow 0.3s ease;
}

.navbar-menu-icon-shadow:hover{
    box-shadow: inset 0px 0px 0px 100px rgba(167, 167, 167,0.7);
    
}
.navbar-menu-icon{
    height: 50px;
    width: 50px;
}

.navbar-side-menu{
    background-color: black;

}
@media (max-width: 200px){
    .site-logo{
        max-width: 125px;
        max-height: 125px;
        width: 100%;
        height: 100%;
    }
    .navbar-menu-icon{
        max-width: 50px;
        max-height: 50px;
        height: 100%;
        width: 100%;
    }
}
